<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6">
                        <router-link to="/" style="" class="d-flex align-center justify-center justify-sm-start">
                            <v-img alt="Quiz Addict - Reper" :src="'/assets/logo.png'" max-width="200"></v-img>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main class="backgroundImg" style="padding-top: 30px; min-height: calc(100vh - 115px)" v-bind:style="background">
            <v-container class="d-flex align-center justify-center" style="height: 100%">
                <div class="ma-12 px-7 py-0 my-0 d-flex flex-column justify-center align-center white--text">
                    <h1 class="white--text">
                        Résultat final:
                        <span style="font-size: 50px">{{ result }}/{{ questions.length }}</span>
                    </h1>
                    <p class="text-center mt-4 mb-10 white--text" style="font-size: 20px">
                        Merci d'avoir pris le temps de passer ce test.
                    </p>
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex justify-center">
                            <v-btn class="mt-2 pt-4 pb-4 text-capitalize next-btn white--text" :class="'btn-general'" :to="'/' + quizkey" dark x-large>
                                Refaire le test!
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-if="quiz.info_compl" class="mt-15">
                        <v-col cols="12" class="pt-0">
                            <v-card class="pa-3">
                                <v-card-title class="d-flex justify-center more-info-title">Informations complémentaires</v-card-title>
                                <v-card-text v-html="quiz.info_compl" class="compl text-center pt-0 mt-0"></v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Home",
    props: ["quizkey"],
    data() {
        return {
            showpartners: false,
            result: localStorage.result,
            quiz: JSON.parse(localStorage.quiz),
        };
    },
    created() {
        this.questions = this.quiz.questions;
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$i18n.locale === "de") {
                    document.title = "Bischfit | by REPER";
                } else {
                    document.title = "Quizaddict | by REPER";
                }
            },
        },
    },
};
</script>

<style lang="scss">
.backgroundImg {
    background: #484879 !important;
    color: #ffffff !important;
    width: 100vw;
}

a .compl {
    width: 50%;
}

.more-info-title {
    color: #333333 !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    line-height: 27px !important;

    @media screen and (min-width: 961px) {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    @media screen and (min-width: 1264px) {
        font-size: 30px !important;
        line-height: 37px !important;
    }
}
.btn-general,
.footer-general {
    background: #f2bb3f !important;
    color: #ffffff !important;
}
.footer {
    min-width: 71px;
}

@media screen and (max-width: 800px) {
    .compl {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .logo {
        width: 130px;
    }
}
@media screen and (max-width: 800px) {
    .logoreper {
        width: 100px;
    }
}
</style>
