import Vue from 'vue'
import VueRouter from 'vue-router'
import QuizHome from '../views/QuizHome.vue'
import QuizQuestion from '../views/QuizQuestion.vue'
import QuizCheck from '../views/QuizCheck.vue'
import QuizResult from '../views/QuizResult.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:quizkey',
    name: 'QuizHome',
    component: QuizHome,
    props: true,
  },
  {
    path: '/:quizkey/question/:nb',
    name: 'QuizQuestion',
    component: QuizQuestion,
    props: true,
  },
  {
    path: '/:quizkey/check/:nb/:correct',
    name: 'QuizCheck',
    component: QuizCheck,
    props: true,
  },
  {
    path: '/:quizkey/result',
    name: 'QuizResult',
    component: QuizResult,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
