import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: 'AIzaSyAfhIOrPjvzYDXc2TZxs5QRGKqyYEcxAjs',
  authDomain: 'quiz-addict-1d74d.firebaseapp.com',
  projectId: 'quiz-addict-1d74d',
  storageBucket: 'quiz-addict-1d74d.appspot.com',
  messagingSenderId: '178159537780',
  appId: '1:178159537780:web:c09f72eb1bffc70eaccb21',
}

export const db = firebase.initializeApp(firebaseConfig).firestore()
