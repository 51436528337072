import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./firebase";
import VueYouTubeEmbed from "vue-youtube-embed";
import vuetify from "./plugins/vuetify";
import { firestorePlugin } from "vuefire";
import titleMixin from "./mixins/title-mixin";

Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" });
Vue.mixin(titleMixin);

Vue.config.productionTip = false;
Vue.use(firestorePlugin);

new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
