<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6">
                        <router-link to="/" style="" class="d-flex align-center justify-center justify-sm-start">
                            <v-img alt="Quiz Addict - Reper" :src="'/assets/logo.png'" max-width="200"></v-img>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main class="backgroundImg" style="padding-top: 30px; min-height: calc(100vh - 120px)" v-bind:style="background">
            <v-container v-if="questions" class="d-flex align-center justify-center">
                <v-card width="1200" class="mt-10 mx-2 mb-4">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <h4
                                    class="question-number mx-8 mt-8"
                                    :style="'font-family: Roboto, sans-serif !important;border-color:' + quiz.main_color + ';color:' + quiz.main_color"
                                >
                                    Question
                                    <span v-text="parseInt(nb) + 1"></span>
                                    /
                                    <span v-text="quiz.questions.length"></span>
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="12" :md="6" order-md="2">
                                <v-img
                                    v-if="question.media"
                                    class="ma-0 mx-8 mt-0 mb-2"
                                    :src="question.media"
                                    contain
                                    max-width="100%"
                                    max-height="300px"
                                    style="border: 6px solid #eee"
                                />
                            </v-col>
                            <v-col cols="12" :sm="12" :md="6" order-md="1" class="pb-0 d-flex flex-column align-center">
                                <h3 v-if="question.context" class="question-text ml-8">
                                    {{ question.context }}
                                </h3>
                                <p class="question-text font-weight-bold ml-8 mt-8" v-text="question.question"></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="12" :md="8" class="d-flex align-end pt-0">
                                <v-card-text class="ml-8 pa-0">
                                    <v-alert dismissible type="warning" v-show="alert">Merci de donner au moins une réponse</v-alert>
                                    <v-radio-group v-if="question.type == 'singleChoice'" v-model="answer" class="mt-0">
                                        <v-radio v-for="answer in question.answers" :key="answer.id" :label="answer.answer" :value="answer.id"></v-radio>
                                    </v-radio-group>

                                    <div v-if="question.type == 'multipleChoice'">
                                        <v-checkbox
                                            v-for="an in question.answers"
                                            :key="an.id"
                                            :label="an.answer"
                                            :value="an.id"
                                            v-model="answers"
                                            class="ma-0 pa-0"
                                            style="min-height: 36px"
                                        ></v-checkbox>
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" :sm="12" :md="4" class="d-flex justify-center align-center justify-md-end align-md-end">
                                <v-btn class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn" :class="'btn-general'" @click="rate" dark>
                                    Contrôler
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Question",
    props: ["nb", "quizkey"],
    data() {
        return {
            showpartners: false,
            alert: false,
            quiz: JSON.parse(localStorage.quiz),
            questions: [],
            question: {},
            answers: [],
            answer: 0,
        };
    },
    methods: {
        rate() {
            if (!this.answer && this.answers.length === 0) {
                this.alert = true;
                return;
            }
            var correct = false;

            if (this.answer) {
                for (let answer of this.question.answers) {
                    if (answer.id === this.answer) {
                        if (answer.correct === "1") {
                            correct = true;
                        }
                    }
                }
            } else {
                var nbCorrect = 0;
                var temp = 0;
                for (let answer of this.question.answers) {
                    if (answer.correct === "1") {
                        nbCorrect++;
                    }
                }

                for (let answer of this.question.answers) {
                    if (answer.correct === "1") {
                        for (let an of this.answers) {
                            if (an === answer.id) {
                                temp++;
                            }
                        }
                    } else {
                        for (let an of this.answers) {
                            if (an === answer.id) {
                                temp--;
                            }
                        }
                    }
                }

                if (temp === nbCorrect) {
                    correct = true;
                } else {
                    correct = false;
                }
            }

            if (correct) {
                localStorage.result = parseInt(localStorage.result) + 1;
            }
            const responses = localStorage.getItem("questionsRep");
            if (responses != null) {
                const tempResp = responses.split(",").concat([this.nb, correct]);
                localStorage.setItem("questionsRep", tempResp.join(","));
            } else {
                localStorage.setItem("questionsRep", [this.nb, correct].join(","));
            }

            this.$router.push("/" + this.quizkey + "/check/" + this.nb + "/" + correct);
        },
    },
    created() {
        const responses = localStorage.getItem("questionsRep");
        if (responses != null) {
            if (responses.split(",").includes(this.nb)) {
                this.$router.push(
                    "/" + this.quizkey + "/check/" + this.nb + "/" + responses.split(",")[responses.split(",").findIndex((resp) => resp == this.nb) + 1]
                );
            }
        }

        this.questions = this.quiz.questions;
        this.question = this.quiz.questions[this.nb];
    },
};
</script>

<style>
.general {
    color: #f2bb3f !important;
}
.btn-general,
.backgroundImg {
    background: #484879 !important;
    color: #ffffff !important;
}
h2 {
    font-family: 'Roboto', sans-serif !important;
    padding-top: 20px;
    font-weight: normal;
    font-size: 180%;
    line-height: 130%;
    padding-bottom: 20px;
}
h1 {
    font-family: 'Roboto', sans-serif !important;
    line-height: 130%;
    padding-top: 20px;
}
h4 {
    font-family: 'Roboto', sans-serif !important;
    font-weight: normal;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.footer {
    min-width: 71px;
}

@media screen and (max-width: 1000px) {
    .logo {
        width: 130px;
    }
}
@media screen and (max-width: 800px) {
    .logoreper {
        width: 100px;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.next-btn {
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
}

.question-number {
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
    margin-bottom: 0px !important;
}

.question-text {
    color: #333333;
    font-family: Roboto !important;
    font-size: 25px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
}
</style>
