<template>
    <div>
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6">
                        <router-link to="/" style="" class="d-flex align-center justify-center justify-sm-start">
                            <v-img alt="Quiz Addict - Reper" :src="'/assets/logo.png'" max-width="200"></v-img>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main class="backgroundImg" style="padding-top: 30px; min-height: calc(100vh - 115px)">
            <v-container v-if="questions" class="d-flex align-center justify-center">
                <v-card width="1200" class="mt-10 mx-2 mb-4">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <h4
                                    class="question-number ml-8 mr-8 mt-8"
                                    :style="'font-family: Roboto, sans-serif !important;border-color:' + quiz.main_color + ';color:' + quiz.main_color"
                                >
                                    Réponse
                                    <span v-text="parseInt(nb) + 1"></span>
                                    /
                                    <span v-text="quiz.questions.length"></span>
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :xs="12" :md="6" order-md="2">
                                <p class="ma-0 pa-0 d-flex justify-center" v-if="correct == 'true'">
                                    <v-img

                                        class="ma-0 mx-8 d-flex align-center text-center"
                                        style="border: 6px solid green"
                                        :src="question.media"
                                        cover
                                        max-width="70%"
                                    >
                                        <v-img src="/pictogrammes_juste.svg" max-height="120px" contain></v-img>
                                    </v-img>
                                </p>
                                <p class="ma-0 pa-0 d-flex justify-center" v-if="correct == 'false'">
                                    <v-img
                                        class="ma-0 mx-8 d-flex align-center text-center"
                                        style="border: 6px solid #ce4244"
                                        :src="question.media"
                                        cover
                                        max-height="70%"
                                    >
                                        <v-img src="/pictogrammes_faux.svg" max-height="120px" contain></v-img>
                                    </v-img>
                                </p>
                            </v-col>
                            <v-col cols="12" :xs="12" :md="6" order-md="1">
                                <v-alert color="#eee" class="mx-8">
                                    <p>
                                        <strong>{{ question.question }}</strong>
                                    </p>

                                    <p v-if="corrects.length === 1">La réponse correcte est :</p>
                                    <p v-else>Les réponses correctes sont :</p>
                                    <ul style="list-style-type: none">
                                        <li v-for="an in question.answers" :key="an.id">
                                            <v-icon color="grey" v-if="an.correct === '1'" style="position: absolute; margin-left: -30px">mdi-check</v-icon>
                                            {{ an.answer }}
                                        </li>
                                    </ul>
                                </v-alert>

                                <p v-if="question.explication_short" v-text="question.explication_short" class="mt-8 mx-8"></p>
                                <p v-if="question.explication && detail === false" class="ml-8">
                                    <a href="#" @click.prevent="detail = true" style="font-size: 18px">En savoir plus</a>
                                </p>

                                <p class="ml-8 mb-10" v-html="question.explication" v-if="detail"></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="12" class="d-flex justify-center align-center justify-md-end align-md-end">
                                <v-btn v-if="!last" class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn" :class="'btn-general'" :to="to" dark>
                                    Prochaine question
                                    <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                </v-btn>

                                <v-btn v-if="last" class="ma-8 pt-4 pb-4 pl-6 pr-6 text-capitalize next-btn" :class="'btn-general'" :to="to" dark>
                                    Terminer
                                    <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Question",
    props: ["nb", "correct", "quizkey"],
    data() {
        return {
            showpartners: false,
            corrects: [],
            quiz: JSON.parse(localStorage.quiz),
            questions: [],
            question: {},
            next: 0,
            to: "",
            detail: false,
            background: "",
            nid: this.quizkey
        };
    },
    created() {
        
        this.questions = this.quiz.questions;
        this.question = this.quiz.questions[this.nb];

        for (let i = 0; i < this.question.answers.length; i++) {
            if (this.question.answers[i].correct == "1") {
                this.corrects.push(this.question.answers[i]);
            }
        }

        let next = parseInt(this.nb) + 1;

        if (next < this.questions.length) {
            this.last = false;
            let next = parseInt(this.nb) + 1;
            this.to = "/" + this.nid + "/question/" + next;
        } else {
            this.last = true;
            this.to = "/" + this.nid + "/result";
        }
    }
};
</script>

<style>
h2 {
    padding-top: 20px;
    font-weight: normal;
    font-size: 180%;
    line-height: 130%;
    padding-bottom: 20px;
}
h1 {
    line-height: 130%;
    padding-top: 20px;
}
h4 {
    font-weight: normal;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.general {
    color: #f2bb3f !important;
}
.footer {
    min-width: 71px;
}
@media screen and (max-width: 1000px) {
    .logo {
        width: 130px;
    }
}
@media screen and (max-width: 800px) {
    .logoreper {
        width: 100px;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.next-btn {
    font-family: "Fredoka One", cursive;
    font-size: 20px !important;
}

.question-number {
    font-family: "Fredoka One" !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
    margin-bottom: 0px !important;
}

.question-text {
    color: #333333;
    font-family: Roboto !important;
    font-size: 25px !important;
    letter-spacing: 0 !important;
    line-height: 35px !important;
}
</style>
