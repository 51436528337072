<template>
    <div v-if="!loading" class="home">
        <header class="header">
            <v-container fluid>
                <v-row class="ml-10 mr-10">
                    <v-col cols="12" sm="6">
                        <router-link to="/" style="" class="d-flex align-center justify-center justify-sm-start">
                            <v-img alt="Quiz Addict - Reper" :src="'/assets/logo.png'" max-width="200"></v-img>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </header>
        <v-main class="backgroundImg-home" style="padding-top: 50px; min-height: calc(100vh - 105px)" :style="background">
            <v-container class="d-flex align-center justify-center" style="height: 100%">
                <v-row class="justify-center text-center">
                    <v-col cols="10">
                        <h1 class="text-center white--text">
                            {{ quiz.name }}
                        </h1>
                    </v-col>
                    <v-col cols="8">
                        <p class="intro text-center white--text">
                            {{ quiz.highlight }}
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            class="pt-5 pb-5 pl-6 pr-6 text-capitalize next-btn go-btn"
                            :class="'btn-general'"
                            :to="'/' + quiz.nid + '/question/0'"
                            x-large
                        >
                            Démarrer le quiz
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import apiCall from "@/utils/api.js";
export default {
    name: "Home",
    props: ["quizkey"],
    data: () => ({
        showpartners: false,
        quiz: {},
        loading: true,
    }),
    created() {
        localStorage.setItem("salon", "");
        localStorage.removeItem("questionsRep");
      
        apiCall({ url: "/" + this.quizkey, method: "get" }).then((resp) => {
            localStorage.quiz = JSON.stringify(resp.data);
            this.quiz = resp.data;
            console.log(this.quiz)
            this.loading = false;
            localStorage.result = 0;
        });
    },
};
</script>

<style lang="scss">
.btn-general,
.backgroundImg-home {
    background: #484879 !important;
    color: #ffffff !important;
}

@media screen and (max-width: 1000px) {
    .logo {
        width: 130px;
    }
}
@media screen and (max-width: 800px) {
    .logoreper {
        width: 100px;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    color: white;
}

.go-btn {
    height: auto !important;
}

.next-btn {
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
}
</style>
